.pi-sort-alt,
.pi-sort-amount-up-alt,
.pi-sort-amount-down {
    font-size: 0.6rem;
    color: white !important;
    cursor: pointer !important;
}
/* .p-datatable .p-column-resizer {
    width: 10px;
    border-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 35%,
        #e4e4e4 25%,
        #e4e4e4 75%,
        rgba(0, 0, 0, 0) 65%
    );
    border-image-slice: 1;
} */
th {
    cursor: grab !important;
    white-space: pre-wrap;
}

.p-datatable .p-datatable-tbody {
    cursor: pointer;
}
.p-datatable.blueTable .p-datatable-wrapper .p-datatable-thead > tr th {
    background: #417ab4 !important;
    color: white !important;
}
.p-datatable.blackTable .p-datatable-wrapper .p-datatable-thead > tr th {
    background: #4e5054 !important;
    color: white !important;
}

.p-datatable .p-datatable-tbody .white:hover {
    background-color: rgb(245, 245, 245);
}
.p-datatable .p-datatable-tbody .blueRow {
    background-color: #d5eaff;
    color: #4e5054;
}
.p-datatable .p-datatable-tbody .blueRow:hover {
    opacity: 0.8;
}
.p-datatable .p-datatable-tbody .redRow {
    background-color: rgb(247, 203, 212);
    color: #4e5054;
}
.p-datatable .p-datatable-tbody .redRow:hover {
    opacity: 0.8;
}
.p-datatable .p-datatable-tbody .orangeRow {
    background-color: rgb(250, 232, 212);
    color: #4e5054;
}
.p-datatable .p-datatable-tbody .orangeRow:hover {
    opacity: 0.8;
}

.p-datatable .p-datatable-tbody .greenRow {
    background-color: rgb(217, 255, 219);
    /* background: repeating-linear-gradient(
        130deg,
        #ff0000 300px,
        #ff0000 305px,
        #1a7d17 305px,
        #1a7d17 310px
    /* ); 
    background-image: repeating-linear-gradient(
        -45deg,
        #ff0000 0 50px,
        #1a7d17 20px 100px
    ); */
    color: #4e5054;
}
.p-datatable .p-datatable-tbody .greenRow:hover {
    opacity: 0.8;
}

.p-datatable .p-datatable-tbody .greenRedRow {
    /* background: rgb(5, 36, 0);
    background: linear-gradient(
        90deg,
        rgba(121, 9, 22, 1) 0%,
        rgba(0, 103, 49, 1) 100%
    ); */
    /* background: repeating-linear-gradient(
        red,
        red 20px,
        #1a7d17 20px,
        #1a7d17 25px
    ); */
    /* background: repeating-linear-gradient(
        90deg,
        #ff0000 15px,
        #ff0000 20px,
        #1a7d17 20px,
        #1a7d17 25px
    ); */
    background-image: repeating-linear-gradient(
        -45deg,
        rgb(255, 242, 244) 0 50px,
        rgb(243, 252, 244) 20px 100px
    );
    color: #4e5054;
}

.p-datatable .p-datatable-tbody .greenRedRow:hover {
    opacity: 0.8;
}

.p-datatable .p-column-resizer {
    border-right: 1px solid white;
}
.p-datatable-resizable > .p-datatable-wrapper {
    overflow-x: hidden;
}
